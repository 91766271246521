import React, { FC, useState } from "react";
import clsx from "clsx";
import yn from "yn";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import memoize from "@RHCommerceDev/utils/memoize";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";
import FormDialog from "@RHCommerceDev/layout-form-dialog";
import EmailSignupSkeleton from "@RHCommerceDev/skeleton-email-signup";
import {
  countries,
  europeanCountries
} from "@RHCommerceDev/resources/countries-config.json";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import EmailSignup from "./EmailSignup";
import { usePageContent } from "customProviders/LocationProvider";

export interface EmailSignUpDialogProps {
  open: boolean;
  onCloseModal: () => void;
}

export interface styleProps {
  isEuCountry: boolean;
}

const useStyles = (props: styleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      backDrop: {
        background: "rgba(0,0,0,0.4)"
      },
      paper: {
        maxWidth: 925,
        width: "calc(100% - 32px)",
        height: !props?.isEuCountry ? "unset" : "600px",
        [theme.breakpoints.down("xl")]: {
          maxWidth: 912
        },
        [theme.breakpoints.down("lg")]: {
          maxWidth: 458,
          height: !props?.isEuCountry ? "unset" : "800px !important"
        },
        [theme.breakpoints.down("md")]: {
          width: "100%"
        }
      },
      dialog: {
        padding: "0px",
        marginTop: "-32px",
        overflowY: "auto",
        [theme.breakpoints.up("sm")]: {
          marginTop: "-56px"
        },
        [theme.breakpoints.up("xl")]: {
          marginTop: "-75px"
        }
      },
      dialogContent: {
        height: "100%"
      },
      leftSideContent: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("lg")]: {
          height: "267px"
        },
        [theme.breakpoints.down("md")]: {
          height: "200px"
        }
      },
      imgClass: {
        objectFit: "cover",
        height: "100%"
      },
      rightSideContent: {
        alignContent: "space-between",
        padding: !props?.isEuCountry ? "100px 40px" : "100px 40px 16px",
        height: "100%",
        [theme.breakpoints.down("lg")]: {
          padding: !props?.isEuCountry ? "50px 30px" : "50px 30px 12px"
        },
        [theme.breakpoints.down("md")]: {
          padding: !props?.isEuCountry ? "40px 20px" : "40px 20px 12px"
        }
      },
      option: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        cursor: "pointer",
        height: "fit-content",
        [theme.breakpoints.down("lg")]: {
          paddingTop: "25px"
        },
        [theme.breakpoints.down("md")]: {
          paddingTop: "25px"
        }
      },
      optionLanguage: {
        color: "#201F1F",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "RHSans-ExtraLight",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "120%", // or '13.2px'
        letterSpacing: "0.44px",
        textTransform: "uppercase"
      },
      selectedOption: {
        fontWeight: 400,
        fontFamily: "RHSans-Roman",
        color: `${theme.palette.common.black} !important`,
        position: "relative",
        "&::after": {
          content: "''",
          position: "absolute",
          width: "14px",
          height: "1px",
          backgroundColor: "black",
          bottom: "-1px",
          left: "0px"
        }
      }
    })
  );

const EmailSignupDialog: FC<EmailSignUpDialogProps> = ({
  open,
  onCloseModal
}) => {
  const env = useEnv();
  const country = useCountry();
  const classes = useStyles({
    isEuCountry:
      europeanCountries.includes(country) &&
      !yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE)
  })();

  const [selectedLanguage, setSelectedLanguage] = useState("en-GB");

  const { pageContent, pageContentLoading: loading } = usePageContent();

  const emailSignupContent = pageContent?.emailSignupContent;

  const handleLanguageSelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const updatedValue = event.currentTarget.dataset["value"] || "en-GB";

    setSelectedLanguage(updatedValue);
  };

  return (
    <FormDialog
      onClose={onCloseModal}
      open={open}
      PaperProps={{ className: classes.paper, square: true }}
      dialogContentClassName={classes.dialog}
      BackdropProps={{
        className: `${classes.backDrop}`
      }}
    >
      {loading ? (
        <EmailSignupSkeleton className={classes.dialogContent} />
      ) : (
        <Grid
          container
          item
          id="component-email-signup-dialog"
          className={classes.dialogContent}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.leftSideContent}
            >
              <img
                src={emailSignupContent?.BANNER_IMG_URL}
                className={classes.imgClass}
                alt="RH Email Banner"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Grid
              container
              item
              justifyContent="center"
              className={classes.rightSideContent}
            >
              <EmailSignup
                onCloseModal={onCloseModal}
                emailSignUpContent={emailSignupContent?.EMAIL_SIGN_UP}
              />
              {!yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE) &&
                countries[country].supportedLanguage.length > 1 && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    className={clsx(classes.option)}
                  >
                    {countries[country].supportedLanguage.map(
                      currentLanguage => {
                        const isSelected =
                          selectedLanguage?.includes(currentLanguage);
                        const currentlanguageValue = `${currentLanguage}-${
                          europeanCountries.includes(country) &&
                          currentLanguage === "en"
                            ? "GB"
                            : country
                        }`;
                        return (
                          <>
                            <div
                              key={`${currentLanguage}`}
                              data-value={currentlanguageValue}
                              className={clsx(classes.optionLanguage, {
                                [classes.selectedOption]: isSelected
                              })}
                              onClick={handleLanguageSelection}
                              tabIndex={0}
                              onKeyPress={handleKeyboardPressWithEvent(
                                handleLanguageSelection
                              )}
                            >
                              {currentLanguage?.toUpperCase()}
                            </div>
                          </>
                        );
                      }
                    )}
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

export default memoize(EmailSignupDialog);
